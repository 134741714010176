/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useGetListingLotsQuery, useSubmitMultiLotBid } from '../../../components/api'
import MUIDataTable from '../../../components/MuiDatatableUtils'
import Spinner from '../../../components/spinner'
import { isUndef, NOOP } from '../../../components/utils'

const isBlank = s => s.trim().length === 0

/**
 * Reponsible for display and updating the bid.
 * @param {*} props
 * @returns
 */
const PlaceBidInput = (props) => {
  const {
    listingId,
    listingLotId,
    buyerId,
    updatedBids,
    doUpdateBid
  } = props

  const key = `${listingId}#${listingLotId}#${buyerId}`
  // state
  const [amount, setAmount] = React.useState(undefined)

  React.useEffect(() => {
    if (!isUndef(updatedBids[key])) setAmount(updatedBids[key].newBid)
  }, [updatedBids, amount, key])

  // on update...
  const onUpdate = (e) => {
    const { value } = e.target
    if (isBlank(value)) {
      doUpdateBid(listingId, listingLotId, buyerId, value)
    } else {
      const newBid = Math.round(value)
      if (!isNaN(newBid)) doUpdateBid(listingId, listingLotId, buyerId, newBid)
    }
  }

  return (<>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text style={{ height: '29px' }}>$</InputGroup.Text>
        </InputGroup.Prepend>
          <Form.Control
          size="sm"
          type="text"
          value={amount}
          onChange={e => onUpdate(e)}
          className='placebid'
          inputMode="numeric"
          />
      </InputGroup>

      {/* { process.env.NODE_ENV !== 'production' && <pre>{JSON.stringify(amount, null, 2)}</pre>} */}
      </>
  )
}

/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props
  const { listingId = '325' } = props
  //   const { listingId } = getQueryParams(props)

  // query
  const submitMultiBidMutation = useSubmitMultiLotBid()
  const listingLotQuery = useGetListingLotsQuery(listingId)
  const { data: { buyers = [] } = {} } = listingLotQuery

  // state
  const [selectedBuyers, setSelectedBuyers] = React.useState([])
  const [updatedBids, setUpdatedBids] = React.useState({})

  /**
   * Stages updates to the buyer's bids.
   * @param {*} listingId
   * @param {*} listingLotId
   * @param {*} buyerId
   * @param {*} newBid
   */
  const doUpdateBid = React.useCallback((listingId, listingLotId, buyerId, newBid) => {
    setUpdatedBids(ps => {
      const key = `${listingId}#${listingLotId}#${buyerId}`
      const { isUpdated, ...prevState } = ps
      const prevEntry = prevState[key]
      const updated = {
        ...prevState,
        [key]: { ...prevEntry, newBid, isUpdated: prevEntry.originalBid !== newBid }
      }
      // materialise the "isUpdated" flag...
      updated.isUpdated = Object.values(updated).some(bid => bid.isUpdated)
      return updated
    })
  }, [])

  // functions
  const selectBuyer = (val) => {
    setSelectedBuyers(val)
    setUpdatedBids({})
    submitMultiBidMutation.reset()
  }
  const onHide = () => {
    setSelectedBuyers([])
    setUpdatedBids({})
    setOpenModal(false)
    submitMultiBidMutation.reset()
  }
  const internalOnSave = () => {
    const { isUpdated, ...bids } = updatedBids
    const validBids = Object.values(bids).filter(bid => bid.isUpdated)
    submitMultiBidMutation.mutate(validBids)
    setSelectedBuyers([])
    setUpdatedBids({})
  }

  React.useEffect(() => {
    // (important!)
    const hasNotYetBeenInitialised = Object.keys(updatedBids).length === 0
    if (listingLotQuery.isSuccess && selectedBuyers.length > 0 && hasNotYetBeenInitialised) {
      // initiliase the updateBids object...
      const buyerId = selectedBuyers[0].company_id
      const map = listingLotQuery.data.listing_lots.reduce((prev, curr) => {
        const { id: listingLotId, buyer_bids = [] } = curr
        const bidEntry = buyer_bids.find(c => c.company_id === buyerId)
        const originalBid = bidEntry ? Math.round(bidEntry.price) : ''
        const newBid = originalBid
        prev[`${listingId}#${listingLotId}#${buyerId}`] = { listingId, listingLotId, buyerId, newBid, originalBid, isUpdated: false }
        return prev
      }, { isUpdated: false })
      setUpdatedBids(map)
    }
  }, [listingLotQuery, listingId, selectedBuyers, updatedBids])

  return <>

        <Modal show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3">Edit bids</h5>
                <p>Select a buyer to edit their bids.</p>

                { listingLotQuery.isLoading
                  ? <div className="d-flex justify-content-center m-4"><Spinner /></div>
                  : <>
            {/* choose buyer */}
            <Typeahead
                clearButton
                id="basic-typeahead-single"
                labelKey="name"
                options={buyers}
                placeholder="Select a buyer"
                onChange={selectBuyer}
                selected={selectedBuyers}
                className="mb-3"
            />

            {/* choose lot */}
            { selectedBuyers.length > 0 && (
                <MUIDataTable
                // title={'Employee List'}
                data={listingLotQuery.data.listing_lots}
                columns={[
                  { label: 'Lot', name: 'lot_number' },
                  {
                    label: 'Bid',
                    name: 'id',
                    options: {
                      customBodyRender: (listingLotId) => {
                        return <PlaceBidInput
                            listingId={listingId}
                            listingLotId={listingLotId}
                            buyerId={selectedBuyers[0].company_id}
                            updatedBids={updatedBids}
                            doUpdateBid={doUpdateBid}
                            />
                      }
                    }
                  }
                ]}
                options={{
                  selectableRows: 'none',
                  pagination: false,
                  jumpToPage: false,
                  rowsPerPage: 15,
                  print: false,
                  search: true,
                  download: false,
                  sort: false,
                  filter: false,
                  viewColumns: false,
                  elevation: 0,
                  setTableProps: () => ({ size: 'small' })
                }}
                />
            )}

          </>}

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary" disabled={selectedBuyers.length === 0 || !updatedBids.isUpdated}>
                        {submitMultiBidMutation.isLoading ? <Spinner /> : <span>Save</span>}
                    </Button>
                </div>

                { submitMultiBidMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Change successful!</Alert> }
                { submitMultiBidMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error updating bids. ' + submitMultiBidMutation.error.message}</Alert> }

                { process.env.NODE_ENV !== 'production' && <>
                    <pre>updatedBids = {JSON.stringify(updatedBids, null, 2)}</pre>
                    <pre>listingLotQuery = {JSON.stringify(listingLotQuery, null, 2)}</pre>
                </>}
            </Modal.Body>
        </Modal>
      </>
}

export default Page
